var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "I18n Table" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeI18n) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-alert", { attrs: { variant: "primary", show: "" } }, [
        _c("div", { staticClass: "alert-body" }, [
          _c("span", [_vm._v("Change language from navbar to view effect.")])
        ])
      ]),
      _c(
        "div",
        { staticClass: "custom-search d-flex justify-content-end" },
        [
          _c("b-form-group", [
            _c(
              "div",
              { staticClass: "d-flex align-items-center" },
              [
                _c("label", { staticClass: "mr-1" }, [
                  _vm._v(_vm._s(_vm.$t("message.seachLabel")))
                ]),
                _c("b-form-input", {
                  staticClass: "d-inline-block",
                  attrs: {
                    placeholder: _vm.$t("message.SearchPlaceholder"),
                    type: "text"
                  },
                  model: {
                    value: _vm.searchTerm,
                    callback: function($$v) {
                      _vm.searchTerm = $$v
                    },
                    expression: "searchTerm"
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c("vue-good-table", {
        attrs: {
          columns: _vm.columns,
          rows: _vm.rows,
          rtl: _vm.direction,
          "search-options": {
            enabled: true,
            externalQuery: _vm.searchTerm
          },
          "select-options": {
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: "custom-class",
            selectionText: "rows selected",
            clearSelectionText: "clear",
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          },
          "pagination-options": {
            enabled: true,
            perPage: _vm.pageLength
          }
        },
        scopedSlots: _vm._u([
          {
            key: "table-column",
            fn: function(props) {
              return [
                props.column.label === "Name"
                  ? _c("span", { staticClass: "text-nowrap" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("message.tableHeader.name")) + " "
                      )
                    ])
                  : props.column.label === "Email"
                  ? _c("span", { staticClass: "text-nowrap" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("message.tableHeader.email")) + " "
                      )
                    ])
                  : props.column.label === "Date"
                  ? _c("span", { staticClass: "text-nowrap" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("message.tableHeader.date")) + " "
                      )
                    ])
                  : props.column.label === "Salary"
                  ? _c("span", { staticClass: "text-nowrap" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("message.tableHeader.salary")) + " "
                      )
                    ])
                  : props.column.label === "Status"
                  ? _c("span", { staticClass: "text-nowrap" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("message.tableHeader.status")) + " "
                      )
                    ])
                  : props.column.label === "Action"
                  ? _c("span", { staticClass: "text-nowrap" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("message.tableHeader.action")) + " "
                      )
                    ])
                  : _c("span", [_vm._v(" " + _vm._s(props.column.label) + " ")])
              ]
            }
          },
          {
            key: "table-row",
            fn: function(props) {
              return [
                props.column.field === "fullName"
                  ? _c(
                      "span",
                      { staticClass: "text-nowrap" },
                      [
                        _c("b-avatar", {
                          staticClass: "mx-1",
                          attrs: { src: props.row.avatar }
                        }),
                        _c("span", [_vm._v(_vm._s(props.row.fullName))])
                      ],
                      1
                    )
                  : props.column.field === "status"
                  ? _c(
                      "span",
                      [
                        _c(
                          "b-badge",
                          {
                            attrs: {
                              variant: _vm.statusVariant(props.row.status)
                            }
                          },
                          [_vm._v(" " + _vm._s(props.row.status) + " ")]
                        )
                      ],
                      1
                    )
                  : props.column.field === "action"
                  ? _c("span", [
                      _c(
                        "span",
                        [
                          _c(
                            "b-dropdown",
                            {
                              attrs: {
                                variant: "link",
                                "toggle-class": "text-decoration-none",
                                "no-caret": ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function() {
                                      return [
                                        _c("feather-icon", {
                                          staticClass:
                                            "text-body align-middle mr-25",
                                          attrs: {
                                            icon: "MoreVerticalIcon",
                                            size: "16"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c(
                                "b-dropdown-item",
                                [
                                  _c("feather-icon", {
                                    staticClass: "mr-50",
                                    attrs: { icon: "Edit2Icon" }
                                  }),
                                  _c("span", [_vm._v("Edit")])
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                [
                                  _c("feather-icon", {
                                    staticClass: "mr-50",
                                    attrs: { icon: "TrashIcon" }
                                  }),
                                  _c("span", [_vm._v("Delete")])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  : _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(props.formattedRow[props.column.field]) +
                          " "
                      )
                    ])
              ]
            }
          },
          {
            key: "pagination-bottom",
            fn: function(props) {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between flex-wrap" },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center mb-0 mt-1" },
                      [
                        _c("span", { staticClass: "text-nowrap" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("message.pagelength")) + " "
                          )
                        ]),
                        _c("b-form-select", {
                          staticClass: "mx-1",
                          attrs: { options: ["3", "5", "10"] },
                          on: {
                            input: function(value) {
                              return props.perPageChanged({
                                currentPerPage: value
                              })
                            }
                          },
                          model: {
                            value: _vm.pageLength,
                            callback: function($$v) {
                              _vm.pageLength = $$v
                            },
                            expression: "pageLength"
                          }
                        }),
                        _c("span", { staticClass: "text-nowrap" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("message.of")) +
                              " " +
                              _vm._s(props.total) +
                              " " +
                              _vm._s(_vm.$t("message.pageText2")) +
                              " "
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("b-pagination", {
                          staticClass: "mt-1 mb-0",
                          attrs: {
                            value: 1,
                            "total-rows": props.total,
                            "per-page": _vm.pageLength,
                            "first-number": "",
                            "last-number": "",
                            align: "right",
                            "prev-class": "prev-item",
                            "next-class": "next-item"
                          },
                          on: {
                            input: function(value) {
                              return props.pageChanged({ currentPage: value })
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "prev-text",
                                fn: function() {
                                  return [
                                    _c("feather-icon", {
                                      attrs: {
                                        icon: "ChevronLeftIcon",
                                        size: "18"
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "next-text",
                                fn: function() {
                                  return [
                                    _c("feather-icon", {
                                      attrs: {
                                        icon: "ChevronRightIcon",
                                        size: "18"
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }